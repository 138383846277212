import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const MeterBilling = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Service - MeterBilling - Title - 1")}</h3>
              <p>
                <Trans
                  i18nKey="Service - MeterBilling - Text - 1"
                  components={{
                    link_download: (
                      <LinkText to="/downloads" title="Download" />
                    ),
                  }}
                />
              </p>

              <h3>{t("Service - MeterBilling - Title - 2")}</h3>

              <p>
                <Trans
                  i18nKey="Service - MeterBilling - Text - 2"
                  components={{
                    link_zev: <LinkText to="/zev" title="ZEV" />,
                    link_WW: (
                      <LinkText to="/waterheat" title="Whater and Heat" />
                    ),
                  }}
                />
              </p>
              <p>{t("Service - MeterBilling - Text - 3")}</p>
              <p>{t("Service - MeterBilling - Text - 4")}</p>

              <h3>{t("Service - MeterBilling - Title - 3")}</h3>
              <p>{t("Service - MeterBilling - Text - 5")}</p>
              <p>{t("Service - MeterBilling - Text - 6")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeterBilling;
